// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ZEMyVvliI", "ACUIIrqym"];

const serializationHash = "framer-42aJ9"

const variantClassNames = {ACUIIrqym: "framer-v-1g5jg61", ZEMyVvliI: "framer-v-apcjjg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 7, ease: [0, 0, 1, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {End: "ACUIIrqym", Start: "ZEMyVvliI"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ZEMyVvliI"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ZEMyVvliI", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear6099ct = activeVariantCallback(async (...args) => {
setVariant("ACUIIrqym")
})

useOnVariantChange(baseVariant, {default: onAppear6099ct})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-42aJ9", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-apcjjg", className)} data-framer-name={"Start"} data-highlight layoutDependency={layoutDependency} layoutId={"ZEMyVvliI"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ACUIIrqym: {"data-framer-name": "End"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1fn8ha6"} layoutDependency={layoutDependency} layoutId={"vw4SbB9LU"} style={{backgroundColor: "var(--token-f07bd819-3132-4b59-b01d-c794567fa989, rgb(255, 255, 255))"}}/><motion.div className={"framer-rwygaf"} layoutDependency={layoutDependency} layoutId={"r3OtaVSmS"} style={{backgroundColor: "var(--token-014f44b0-4365-49a2-a3e1-43e76509186b, rgb(173, 255, 133))"}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-42aJ9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-42aJ9 .framer-1gly6tp { display: block; }", ".framer-42aJ9 .framer-apcjjg { height: 1px; overflow: visible; position: relative; width: 482px; }", ".framer-42aJ9 .framer-1fn8ha6 { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; top: 0px; width: 100%; }", ".framer-42aJ9 .framer-rwygaf { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; top: 0px; width: 0%; }", ".framer-42aJ9.framer-v-1g5jg61 .framer-rwygaf { width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 482
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ACUIIrqym":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerAXwV6t4cT: React.ComponentType<Props> = withCSS(Component, css, "framer-42aJ9") as typeof Component;
export default FramerAXwV6t4cT;

FramerAXwV6t4cT.displayName = "Slider / Progress Bar";

FramerAXwV6t4cT.defaultProps = {height: 1, width: 482};

addPropertyControls(FramerAXwV6t4cT, {variant: {options: ["ZEMyVvliI", "ACUIIrqym"], optionTitles: ["Start", "End"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerAXwV6t4cT, [])